import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const ContrastIcon: FC<TIcon> = React.memo(
  ({ color = '#000', width = 30, height = 23 }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9166 8.39499L18.0805 14.5358M11.9195 8.39788L7.13891 3.63522M7.13891 3.63522C9.48178 2.12907 12.2117 1.32975 15 1.33351C21.5069 1.33351 27.014 5.59385 28.8667 11.4668C27.8438 14.6957 25.7199 17.4666 22.8626 19.2999L7.13891 3.63522ZM18.0834 14.5387L22.8641 19.3013M17.7245 21.3468C16.8263 21.516 15.9142 21.6009 15 21.6002C8.49317 21.6002 2.98603 17.3398 1.13336 11.4668C1.63216 9.88618 2.40043 8.4029 3.40451 7.082L17.7245 21.3468ZM11.9166 8.39644C12.7342 7.58192 13.8431 7.12434 14.9993 7.12434C16.1555 7.12434 17.2644 7.58192 18.082 8.39644C18.8996 9.21095 19.3589 10.3157 19.3589 11.4676C19.3589 12.6195 18.8996 13.7242 18.082 14.5387L11.9166 8.39644Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);
