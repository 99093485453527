import { createAsyncThunk } from '@reduxjs/toolkit';
import { mapFormStateToFormData } from 'adapters/form/formData';
import { ApiFormV2 } from 'api/entities_v2/ApiFormV2';
import { formApiV2 } from 'api/formApi';
import { IFormFieldV2, IFormStateV2 } from 'pages/form/types';

export const fetchFormBySlugV2 = createAsyncThunk<ApiFormV2, { slug: string }>(
  'formV2/fetchFormBySlugV2',
  async ({ slug }, thunkAPI) => {
    const response = await formApiV2.getFormBySlug({
      slug,
    });
    const data = response.data;
    if (response.data === null) {
      return thunkAPI.rejectWithValue(data);
    }
    return data!;
  },
);

export const submitForm = createAsyncThunk<
  { data: { message: string }; nextPageId: number },
  { formState: IFormStateV2; sessionID: string; nextPageId: number }
>(
  'formV2/submitForm',
  async ({ formState, sessionID, nextPageId }, thunkAPI) => {
    try {
      const formArray: IFormFieldV2[] = [];

      Object.keys(formState.extraFields).forEach((key: string) => {
        formArray.push(formState.extraFields[key]);
      });

      const selectedConnectionFieldUrls: { key: string; value: any }[] = Object.values(formState.extraFields)?.find(
        (e) => e.type === 'connection_channel', // @ts-ignore
      )?.botUrls;
      const answers = await mapFormStateToFormData(formArray);
      if (answers) {
        const key = `${
          answers.find((e) => ['telegram', 'viber'].includes(e.value))?.value
        }_url`;
        const url = selectedConnectionFieldUrls?.find(
          (e) => e.key === key,
        )?.value;
        // eslint-disable-next-line no-restricted-globals
        if (url) open(url + sessionID);
        const response = await formApiV2.submitForm({
          answers,
          sessionID,
        });
        return { data: response.data, nextPageId };
      }
      return thunkAPI.rejectWithValue('No answers');
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);
