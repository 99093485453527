import React from 'react';
import { ErrorPageStyles } from './ErrorPageStyles';
import errorImage from 'assets/images/error400.png';
import { useParams } from 'react-router-dom';
import { LogoFillIcon, LogoIcon } from 'assets';

function ErrorPage() {
  const { errorCode } = useParams();

  return (
    <ErrorPageStyles>
      <div className="container">
        <div className="logo-wrapper">
          <LogoIcon width={64} height={64} />
          <LogoFillIcon width={64} height={64} />
        </div>
        <h1>404</h1>
        <h5>Такої сторінки не існує</h5>
        <p>Схоже, це неправильна адреса, або сторінка переїхала</p>
      </div>
    </ErrorPageStyles>
  );
}

export default ErrorPage;
