import { FC } from 'react';
import { PictureContainer } from './styles';
import { TPictureProps } from './types';
import { proxyFile } from 'utils/proxyFile';

export const PictureField: FC<TPictureProps> = ({ url }) => (
  <PictureContainer>
    <div className="pictureWrapper">
      <div className="pictureContainer">
        <img src={proxyFile(url)} alt="Actual mascot" />
      </div>
    </div>
  </PictureContainer>
);
