import { useAppSelector } from 'state/hooks';

export const useInterfaceSettings = () => {
  const {
    fontScale, grayMode, fullWidth, activeTab,
  } = useAppSelector(
    (state) => state.interface,
  );
  return {
    fontScale,
    grayMode,
    fullWidth,
    activeTab,
  };
};
