import styled from 'styled-components';

export const DropdownContainer = styled.div`
  .dropdownWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .titleContainer {
      width: 100%;
    }

    .placeholder {
      color: black;
      font-family: e-Ukraine, sans-serif;
      font-size: 14px;
      color: rgb(76, 82, 83);
    }

    .container {
      border: none;
    }

    .container-focused {
      border: none;
    }

    .control {
      border: 1px solid rgb(229, 229, 229);
    }

    .control-focused {
      border: none;
    }

    .singleValue {
      font-family: e-Ukraine, sans-serif;
      font-size: 14px;
      color: rgb(76, 82, 83);
    }

    .option {
      font-family: e-Ukraine, sans-serif;
      font-size: 14px;
      color: rgb(76, 82, 83);
      background-color: transparent;
      /* background-color: red; */
    }

    .option-selected {
      font-family: e-Ukraine, sans-serif;
      font-size: 14px;
      color: rgb(76, 82, 83);
      background-color: transparent;
      /* background-color: yellow; */
    }

    .option-focused {
      font-family: e-Ukraine, sans-serif;
      font-size: 14px;
      color: rgb(76, 82, 83);
      background-color: transparent;
      /* background-color: blue; */
    }

    .indicator {
      border: none;
    }

    .indicator-focused {
      rotate: 3.14rad;
    }

    .separator {
      display: none;
    }
  }
`;
