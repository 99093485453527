import styled from 'styled-components';

export const HeaderContainer = styled.div<{ isOpened: boolean }>`
  .header {
    height: 52px;
    padding: 0 32px;
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;

    .floatingMenu {
      transition: all 0.3s;
      display: flex;
      justify-content: ${({ isOpened }) => (isOpened ? 'space-evenly' : 'center')};
      align-items: center;
      position: relative;
      bottom: 3px;
      .floatingBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
        width: 37px;
        background-color: white;
        height: 37px;
        border: 1px solid #000;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border-top-left-radius: ${({ isOpened }) => (isOpened ? '0px' : '12px')};
        border-bottom-left-radius: ${({ isOpened }) => (isOpened ? '0px' : '12px')};
        border-left: ${({ isOpened }) => (isOpened ? 'transparent' : '1px solid #000')};
        z-index: 100;
        svg {
        }
      }
      .items {
        z-index: 99;
        transition: all 0.3s;
        justify-content: space-evenly;
        background-color: white;
        height: 35px;
        transition: all 0.3s;
        position: absolute;
        display: flex;
        flex-direction: row;
        border-top-left-radius: 12px;
        border-right: transparent;
        border-bottom-left-radius: 12px;
        width: ${({ isOpened }) => (isOpened ? '100px' : '0px')};
        left: ${({ isOpened }) => (isOpened ? '-100px' : '0px')};
        border: ${({ isOpened }) => (isOpened ? '1px solid #000' : 'transparent')};
        .user-interface {
          svg {
            transition: all 0.3s;
            opacity: ${({ isOpened }) => (isOpened ? 1 : 0)};
          }
        }
      }
    }

    .dividingLine {
      margin: 0 auto;
      bottom: -12px;
      border-radius: 6px;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }

    .headerInnerContainer {
      display: flex;

      justify-content: space-between;

      @media (min-width: 360px) {
      }

      .languageSelect {
        padding-top: 14px;
        position: relative;
        color: #000000;
      }

      .selectedLanguage {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        gap: 5px;
        align-items: center;

        img {
          max-width: 25px;
        }

        &.opened {
          svg {
            transform: rotate(-180deg);
            transition: all 0.3s;
          }
        }

        svg {
          margin-left: 8px;
          margin-bottom: 2px;
          transition: all 0.3s;
        }
      }

      .logoImage {
        .logo {
          max-height: 35px;
          object-fit: contain;
        }
      }

      .languages {
        width: auto;
        min-width: 70px;
        position: absolute;
        top: 35px;
        left: -4px;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 4px 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

        button {
          height: 28px;
          padding: 0 12px;
          display: flex;
          gap: 5px;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #000;

          img {
            max-width: 20px;
          }
        }
      }
    }

    .nodeNumber {
    }
  }
`;
