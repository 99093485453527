import styled from 'styled-components';

export const SquareCheckboxStyles = styled.div<{
  elementsColor: string | undefined;
  defaultCheckboxBorderColor?: string | undefined;
}>`
  position: relative;
  .fieldName {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .checkboxDefault {
    background-color: transparent;
    top: -1px;
    left: -2px;
    width: 18px;
    height: 18px;
    z-index: -10;
    position: absolute;
  }

  .checkboxWrapper {
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-gap: 12px;
    cursor: pointer;
  }

  .customizedCheckbox {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid
      ${({ defaultCheckboxBorderColor }) => defaultCheckboxBorderColor || '#E5E5E5'};
    box-sizing: border-box;
  }

  .customizedCheckboxChecked {
    border: 1px solid ${({ elementsColor }) => elementsColor || '#eee'};
  }

  .checkboxText {
    line-height: 22px;
    font-size: 16px;

    @media (max-width: 400px) {
      font-size: 14px;
    }
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
