import styled from 'styled-components';

export const TextContainer = styled.div<{
  fontFamily?: string;
  isFontWeightBold?: boolean;
}>`
  .captionWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid black; */

    .captionContainer {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      /* background-color: red; */
      p {
        font-family: e-Ukraine, sans-serif;
        /* font-family: ${({ fontFamily }) => `${fontFamily || 'e-Ukraine'}, sans-serif`}; */
        font-size: 22px;
        line-height: 24px;
        color: #323232;
        font-weight: 700; // ${({ isFontWeightBold }) => `${isFontWeightBold ? '700' : '500'}`};
      }
    }

    .descriptionContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      p {
        /* font-family: ${({ fontFamily }) => `${fontFamily || 'e-Ukraine'}, sans-serif`}; */
        font-family: e-Ukraine, sans-serif;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #323232;
        font-weight: 500; // ${({ isFontWeightBold }) => `${isFontWeightBold ? '700' : '500'}`};
      }
    }
  }
`;
