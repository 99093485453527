import styled from 'styled-components';

export const ErrorPageStyles = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &::before {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    background-image: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 50.71%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
    background-size: 150vw 150vh;
    animation: 15s infinite granimate;
  }

  @keyframes granimate {
    0%,
    100% {
      background-position: 0 25%;
    }
    25%,
    75% {
      background-position: 50% 50%;
    }
    50% {
      background-position: 100% 100%;
    }
  }

  .container {
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: "e-ukraine";

    h1 {
      letter-spacing: -0.02em;
      color: #000;
      font-size: 100px;
      line-height: 100%;
      margin-bottom: 25px;
      margin-top: 80px;

      @media screen and (max-width: 768px) {
        margin-top: 30px;
        font-size: 64px;
      }
    }

    h5 {
      font-size: 28px;
      line-height: 150%;
      letter-spacing: -0.02em;
      margin-bottom: 10px;

      @media screen and (max-width: 768px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #000;
      margin-bottom: 60px;

      @media screen and (max-width: 768px) {
        margin-bottom: 0px;
        font-size: 14px;
      }
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;
