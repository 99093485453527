import { FC } from 'react';
import { ParagraphContainer } from './styles';
import { TParagraphProps } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';

export const ParagraphField: FC<TParagraphProps> = ({ index, text }) => {
  const { fontFamily, fontUrl, isFontWeightBold } = useFontStyles();
  return (
    <ParagraphContainer
      fontFamily={fontFamily}
      isFontWeightBold={isFontWeightBold}
    >
      <div
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive */
        tabIndex={0}
        id="question"
        aria-label={text}
        className="pictureWrapper"
      >
        <div className="pictureContainer">
          <p>{text}</p>
        </div>
      </div>
    </ParagraphContainer>
  );
};
