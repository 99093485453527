import { IApiPage } from 'api/entities_v2/ApiFormV2';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { fetchFormBySlugV2 } from 'state/thunk';
import { IFormFieldV2 } from './types';
import { mapPagesToFormFields } from './utils';

type TUseForm = {
  selectedPage?: IApiPage;
  currentFormFields: { [key: string]: IFormFieldV2 };
  isSubmit: boolean;
  isLoading: boolean;
  statusCode?: number;
};

export const useForm = (selectedLanguage: string, fraudKey: string, fraud: boolean): TUseForm => {
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const { page, pagesHistory } = useAppSelector((state) => state.navigation);
  const { localization, statusCode, isLoading } = useAppSelector(
    (state) => state.formV2,
  );
  const storedThunks = JSON.parse(localStorage.getItem(fraudKey) as string);

  useEffect(() => {
    dispatch(fetchFormBySlugV2({ slug: slug ?? '' }));
  }, []);

  const selectedFormLocalization = useMemo(
    () => localization.find((loc) => {
      if (selectedLanguage) {
        return (
          loc.language.name.toLowerCase() === selectedLanguage.toLowerCase()
        );
      }
      return loc.isDefault;
    }),
    [localization, statusCode, selectedLanguage],
  );

  const selectedPage = useMemo(() => {
    const currPage = selectedFormLocalization?.pages?.find(
      (e) => e?.id === page,
    )!;
    return {
      ...currPage,
      fields: currPage?.fields?.map((e) => ({ ...e, id: `${e?.id}|||s` })),
    };
  }, [selectedFormLocalization, page, selectedLanguage]);

  const currentFormFields: { [key: string]: IFormFieldV2 } = useMemo(() => {
    const fields: { [key: string]: IFormFieldV2 } = {};
    mapPagesToFormFields(localization ?? [], selectedLanguage)?.forEach(
      (field) => {
        if (field) {
          fields[`${field?.id}|||s`] = field;
        }
      },
    );
    return fields;
  }, [localization, selectedLanguage]);
  const isSubmit = useMemo(
    () => (selectedPage
      ? selectedPage?.options?.find((opt) => opt.key === 'is_submit')
        ?.value === 'true'
      : false),
    [selectedPage],
  );

  return {
    // @ts-ignore
    selectedPage: fraud ? storedThunks.thanksPage : selectedPage,
    currentFormFields,
    isSubmit,
    isLoading,
    statusCode,
  };
};
