import styled from 'styled-components';

export const RadioButtonStyles = styled.div<{
  elementsColor: string | undefined;
  defaultRadioBorderColor?: string | undefined;
  borderWidth?: string | undefined;
  checkedRadioBtnSize?: string | undefined;
  checkedRadioBtnBorderRadius?: string | undefined;
}>`
  position: relative;
  .fieldName {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 8px;
  }
  .radioDefault {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    top: -2px;
    left: -7px;
    width: 20px;
    height: 20px;
    background-color: transparent;
  }
  .radioWrapper {
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-gap: 12px;
    align-items: center;
  }
  .customizedRadio {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border: ${({ borderWidth }) => borderWidth || '1'}px solid
      ${({ defaultRadioBorderColor }) => defaultRadioBorderColor || '#E5E5E5'};
    border-radius: 50%;
    box-sizing: border-box;
  }

  .customizedRadioChecked {
    border: ${({ borderWidth }) => borderWidth || '1'}px solid
      ${({ elementsColor }) => elementsColor || '#eee'};
  }

  .checked {
    width: ${({ checkedRadioBtnSize }) => checkedRadioBtnSize || '12'}px;
    height: ${({ checkedRadioBtnSize }) => checkedRadioBtnSize || '12'}px;
    border-radius: 50%;
    background-color: ${({ elementsColor }) => elementsColor || '#eee'};
  }

  .radioText {
    line-height: 22px;
    font-size: 16px;
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
