import { ApiFormV2 } from 'api/entities_v2/ApiFormV2';
import { IFormStylesV2 } from 'state/slices/stylesSliceV2/types';

export function formStylesAdapter(apiForm: ApiFormV2): IFormStylesV2 {
  //   const formStyles = apiForm.form.styles ? apiForm.form.styles : [];
  const sendButtonText = apiForm.nodeForm.form.options?.find(
    (item) => item.key === 'send_button_text',
  )?.value;

  const withStep = apiForm.nodeForm.form.options?.find((item) => item.key === 'withSteps')
    ?.value === 'true';

  const googlePlaceId = apiForm.nodeForm.node.metadata.find(
    (el) => el.key === 'googlePlaceId',
  )?.value;
  return {
    id: apiForm.nodeForm.form.id,
    title:
      apiForm.nodeForm.form.options?.find((item) => item.key === 'form_title')
        ?.value || undefined,
    sessionId: apiForm.sessionID,
    name: apiForm.nodeForm.form.name,
    logo: apiForm.nodeForm.form.logo,
    logoLocation:
      apiForm.nodeForm.form.styles?.find((item) => item.key === 'logoLocation')
        ?.value || 'left',
    // defaultMascot:
    //   apiForm.form.options
    //     ?.find((item) => item.key === 'default_mascot')
    //     ?.value.split('|||')[1] || undefined,
    nodeAddress:
      apiForm.nodeForm.node.metadata.find((el) => el.key === 'address')
        ?.value || '',
    backgroundColor: apiForm.nodeForm.form.styles
      ?.find((item) => item.key === 'background_color')
      ?.value.split('|||')[1],
    // extraFields: mapApiFieldsToIExtraFields(
    //   apiForm.form.fields.filter(
    //     (field) => field.type !== 'document'
    //       && field.type !== 'send_button'
    //       && field.type !== 'thank_you_page',
    //   ),
    // ),
    // sendButton: apiSendButton
    //   ? mapApiSendButtonToISendButton(apiSendButton)
    //   : { ...defaultSendButton, type: 'send_button' },
    // documents: mapApiDocumentsToIDocuments(
    //   apiForm.form.fields.filter((field) => field.type === 'document'),
    // ),
    // thankYouPage: apiThankYouPage
    //   ? mapApiThankYouPageToIThankYouPage(apiThankYouPage)
    //   : { ...defaultThankYouPage, type: 'thank_you_page' },
    anonymous: apiForm.nodeForm.form.anonymous === true,
    // languages: mapApiLanguagesToILanguages(apiForm.languages),
    // currentLanguage: apiFormLanguage
    //   ? mapApiLanguageToILanguage(apiFormLanguage!)
    //   : { name: '', icon: '' },
    elementsColor:
      apiForm.nodeForm.form.styles
        ?.find((item) => item.key === 'elements_color')
        ?.value.split('|||')[1] ?? '',
    // currentFieldId: apiForm.form.fieldID,
    nodeCode: apiForm.nodeForm.node.code,
    sendButtonText,
    // formFontUrl:
    //   apiForm.form.styles?.find((item) => item.key === 'form_font')?.value
    //   || '',
    showNodeCode:
      apiForm.nodeForm.form.options?.find(
        (item) => item.key === 'show_node_code',
      )?.value === 'true',
    // sourceID: apiForm.nodeForm. .node.sourceID,
    companyID: apiForm.nodeForm.node.companyID,
    nodeID: apiForm.nodeForm.node.id,
    googlePlaceId: googlePlaceId || '',
    withStep,
  };
}
