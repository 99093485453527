import styled from 'styled-components';

export const TopSectionContainer = styled.div<{ fontFamily?: string }>`
  width: 100%;
  .topSection {
    width: 100%;
    @media (max-width: 420px) {
    }

    .logoContainer {
      margin-bottom: 20px;
      width: 100%;
      @media (min-width: 767px) {
        padding-top: 30px;
      }
      .logo {
        width: 100%;
        img {
          width: 35%;
          max-width: 150px;
        }
        .emptyBlock {
          width: 80px;
        }

        h1 {
          flex-grow: 1;
          font-family: ${({ fontFamily }) => `${fontFamily || 'e-Ukraine'}, sans-serif`};
          text-align: center;
          font-weight: bold;
          font-size: 34px;
          line-height: 40px;
          color: #242424;
        }

        &.left,
        &.center,
        &.right {
          display: flex;
          align-items: center;
        }

        &.center {
          flex-direction: column;
        }

        &.right {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
      }
    }

    .titleContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .companyAddress {
        font-family: e-Ukraine, sans-serif;
        margin-top: 8px;
        margin-bottom: 14px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: rgb(32, 32, 32);
        font-weight: 700;
      }

      .title {
        margin-top: 16px;
        margin-bottom: 21px;
        font-size: 22px;
        line-height: 24px;
        text-align: center;
        color: #202020;
        font-weight: 700;
      }

      .description {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #202020;

        &:first-child {
          margin-top: 16px;
        }
        &:last-child {
          margin-bottom: 21px;
        }
      }
    }
  }
`;
