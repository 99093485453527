import { FC } from 'react';
import { TextContainer } from './styles';
import { TCaptionProps } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';

export const CaptionField: FC<TCaptionProps> = ({
  index,
  text,
  description,
}) => {
  const { fontFamily, fontUrl, isFontWeightBold } = useFontStyles();
  return (
    <TextContainer fontFamily={fontFamily} isFontWeightBold={isFontWeightBold}>
      <div className="captionWrapper">
        <div
          /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive */
          tabIndex={0}
          id="question"
          aria-label={text}
          className="captionContainer"
        >
          <p>{text}</p>
        </div>
        <div
          /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive */
          tabIndex={0}
          id="question"
          aria-label={description}
          className="descriptionContainer"
        >
          <p>{description}</p>
        </div>
      </div>
    </TextContainer>
  );
};
