import styled from 'styled-components';

export const BackButtonStyles = styled.div<{
  fontFamily: string | undefined;
  isFontWeightBold: boolean;
}>`
  @media (max-width: 370px) {
    margin-top: 10px;
  }

  @media (min-width: 371px) {
    width: 146px;
  }

  button {
    width: 100%;
    height: 48px;
    max-width: 146px;
    background-color: #ffff;
    color: #000000;
    border-radius: 40px;
    border: 2px solid #000;
    font-weight: ${({ isFontWeightBold }) => `${isFontWeightBold ? '700' : '500'}`};
    font-size: 16px;
    font-family: ${({ fontFamily }) => `${fontFamily || 'e-Ukraine'}, sans-serif`};

    @media (max-width: 370px) {
      min-width: 256px;
    }

    @media (max-width: 300px) {
      min-width: 220px;
    }
  }
`;
