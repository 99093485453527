import styled from 'styled-components';

export const SimpleFieldStyles = styled.div<{
  fontFamily: string | undefined;
  placeHolderColor?: string;
  inputBackground?: string;
}>`
  textarea,
  input,
  .react-tel-input .form-control,
  .react-tel-input .flag-dropdown {
    font-family: ${({ fontFamily }) => `${fontFamily || 'e-Ukraine'}, sans-serif`};
    font-size: 14px;
    line-height: 18px;
    color: #4c5253;
    border-bottom: 1px solid #000;
    background-color: transparent;
    width: 100%;
    max-width: 480px;
    border: none;
    box-sizing: border-box;
  }

  .inputContainer {
    background-color: white;

    .inputRaw {
      background-color: white;
      border-bottom: 2px solid #000;
    }
  }

  textarea {
    resize: none;
    /* padding: 12px 16px; */
    min-height: 78px;
    max-height: 200px;

    &:not(:last-child) {
      /* margin-bottom: 12px; */
    }
  }

  input {
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    /* background-color: ${({ inputBackground }) => `${inputBackground || ' #F9F9F9'}`}; */

    &::placeholder {
      color: ${({ placeHolderColor }) => `${placeHolderColor || '#4C5253'}`};
    }
  }

  .react-tel-input {
    position: relative;

    .form-control {
      width: 100%;
      max-width: 480px;
      height: 42px;
      padding-left: 68px;
    }

    .flag-dropdown {
      width: 60px;

      .selected-flag {
        width: 60px;
        background-color: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        .flag {
          position: initial;
          top: inherit;
          margin: 0;

          .arrow {
            display: none;
          }
        }
      }

      .country-list {
        width: 360px;
        margin-left: 0;

        .search {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 8px;
          align-items: center;
          padding: 0 16px;

          width: 100%;
          background-color: #f9f9f9;
          border-radius: 3px;
          border: none;
          box-sizing: border-box;

          // font-family: ;
          font-size: 16px;
          line-height: 18px;
          color: #4c5253;
        }

        .search-box {
          padding: 0;
          border: none;
          border-radius: 0;
          background-color: transparent;
        }
      }
    }
  }
`;
