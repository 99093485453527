import React, { useCallback, useRef } from 'react';
import { RadioButtonStyles } from './RadioButtonStyles';
import { RadioProps } from './types';
import { useAppSelector } from 'state/hooks';
import { useFormStyles } from 'hooks/styles/useStyles';
import { useFormikContext } from 'formik';
import { IFormStateV2 } from 'pages/form/types';

export function RadioButton({
  name,
  value,
  checked,
  onChange,
  extraBlockStyles,
  radioButtonWrapperStyles,
  customizedRadioContainerStyles,
  children,
  index,
}: RadioProps) {
  const { touched, errors, handleBlur } = useFormikContext<IFormStateV2>();
  // TODO FORM STYLES RADIO
  const { notCheckedElementsColor, formRadioButtonStyles } = useAppSelector(
    (state) => state.form,
  );

  const { elementsColor } = useFormStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }, [inputRef]);

  return (
    <RadioButtonStyles
      style={extraBlockStyles}
      elementsColor={elementsColor}
      defaultRadioBorderColor={notCheckedElementsColor}
      borderWidth={formRadioButtonStyles.radioBtnBorderWidth}
      checkedRadioBtnSize={formRadioButtonStyles.checkedRadioBtnInnerRoundSizes}
    >
      <input
        tabIndex={-1}
        aria-label={value}
        className="radioDefault"
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={(event) => (onChange ? onChange(event) : null)}
        ref={inputRef}
        onBlur={(e) => {
          handleBlur(e);
        }}
      />
      <div>
        <label
          className="radioWrapper"
          style={radioButtonWrapperStyles}
          htmlFor={`${value}_${index}`}
        >
          <div style={customizedRadioContainerStyles}>
            <button
              id={`${value}_${index}`}
              type="button"
              className={
                checked
                  ? 'customizedRadio customizedRadioChecked'
                  : 'customizedRadio'
              }
              onClick={handleOnClick}
            >
              {checked && <div className="checked" />}
            </button>
          </div>
          <div className="radioText">{children}</div>
        </label>
      </div>
    </RadioButtonStyles>
  );
}
