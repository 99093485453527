import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchFormBySlugV2 } from '../../thunk';

export const formSettingsSliceV2 = createSlice({
  name: 'stylesV2',
  initialState,
  reducers: {
    setMoscot: (state, action: PayloadAction<{ url: string }>) => {
      state.moscotUrl = action.payload.url;
    },
    setBackgroundColor: (state, action: PayloadAction<{ color: string }>) => {
      state.backgroundColor = action.payload.color;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormBySlugV2.fulfilled, (state, data) => {
      const {
        payload: {
          sessionID,
          nodeForm: { form, node },
        },
      } = data;

      localStorage.setItem('sessionID', sessionID)

      const withStep = form.options?.find((item) => item.key === 'withSteps')?.value
        === 'true';
      const googlePlaceId = node.metadata.find(
        (el) => el.key === 'googlePlaceId',
      )?.value;
      state.form.id = form.id;
      state.form.title = form.options?.find((item) => item.key === 'form_title')?.value
        || undefined;
      state.sessionId = sessionID;
      state.form.name = form.name;
      state.form.logo = form.logo;
      state.form.logoLocation = form.styles?.find((item) => item.key === 'logoLocation')?.value
        || 'left';
      state.node.nodeAddress = node.metadata.find((el) => el.key === 'address')?.value || '';
      state.anonymous = form.anonymous === true;
      state.node.nodeCode = node.code;
      state.node.showNodeCode = form.options?.find((item) => item.key === 'show_node_code')?.value
        === 'true';
      state.companyID = node.companyID;
      state.node.nodeID = node.id;
      state.googlePlaceId = googlePlaceId || '';
      state.withStep = withStep;
      state.form.isPageByPageForm = form.localizations[0].pages.length > 2;
    });
    builder.addCase(fetchFormBySlugV2.rejected, (state, data) => {});
  },
});

const { actions, reducer } = formSettingsSliceV2;
// Extract and export each action creator by name
export const { setMoscot, setBackgroundColor } = actions;
// Export the reducer, either as a default or named export
export default reducer;
