import ApiResponse from 'api/entities/ApiResponse';
import { ApiFormV2 } from 'api/entities_v2/ApiFormV2';
import { IFormSubmitRequest } from 'pages/form/types';

const parseConversionParams = (url: string) => {
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  const conversionParams = Object.keys(params).filter((param) => [
    'smsItemID',
    'viberItemID',
    'emailItemID',
  ].includes(param));
  const urlStr = new URL(url);
  conversionParams.forEach((param) => {
    urlStr.searchParams.append(param, params[param]);
  });
  return urlStr.href;
};

export class FormApiV2 {
  // eslint-disable-next-line class-methods-use-this
  public async getFormBySlug({
    slug,
  }: {
    slug: string;
  }): Promise<ApiResponse<ApiFormV2 | null>> {
    try {
      const session = localStorage.getItem('sessionID')
      const response = await fetch(
        `${parseConversionParams(
          `${process.env.REACT_APP_API_HOST!}/client/${slug}${session ? `?session=${session}` : ''}`,
        )}`,
      );
      const data = await response.json();
      return {
        data,
        statusCode: 200,
      };
    } catch (e) {
      console.log(e);
      return {
        data: null,
        statusCode: 500,
      };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public async submitForm(
    data: IFormSubmitRequest,
  ): Promise<ApiResponse<{ message: string }>> {
    const response = await fetch(
      parseConversionParams(`${process.env.REACT_APP_API_HOST!}/client`),
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      },
    );
    const response_data = await response.json();
    return {
      data: response_data,
      statusCode: 200,
    };
  }
}
